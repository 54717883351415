import React, { useState, useEffect } from "react"
import { window } from "browser-monads"

const VideoBanner = props => {
  const { text, videoSrc, imageSrc } = props

  const [videoBg, setVideoBg] = useState(
    window.innerWidth >= 1024 ? true : false
  )

  const checkVideoBg = () => {
    setVideoBg(window.innerWidth > 1024 ? true : false)
  }

  useEffect(() => {
    window.addEventListener("resize", checkVideoBg, true)

    return () => {
      window.removeEventListener("resize", checkVideoBg, true)
    }
  }, [])

  return (
    <div id="page-background-wrap">
      <h1>{text}</h1>
      {videoBg ? (
        <video id="page-background" nocontrols="true" muted autoPlay>
          <source src={`${videoSrc}`} type="video/mp4" />
        </video>
      ) : (
        <div
          id="page-background"
          style={{
            backgroundImage: `url(${imageSrc})`,
            filter: "brightness(0.7)",
          }}
        ></div>
      )}
    </div>
  )
}

export default VideoBanner
